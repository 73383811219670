import { memoize } from "lodash";
import { compose } from "@reduxjs/toolkit";

const formatSecondsPart = memoize((totalSeconds) => {
  const secondsPart = (totalSeconds % 60).toString();
  return secondsPart.length === 2 ? secondsPart : secondsPart.padStart(2, "0");
});

export const formatTimeRemainingDisplay = memoize((msLeft) => {
  const normalizedSecsLeft = Math.max(Math.round(msLeft / 1000), 0);
  return `${Math.floor(normalizedSecsLeft / 60)}:${formatSecondsPart(
    normalizedSecsLeft
  )}`;
});

export const formatTimeStr = (msIntOrISOStr) => {
  return new Date(msIntOrISOStr).toLocaleTimeString("en-US", {
    hour12: false,
    timeStyle: "short",
  });
};

export const getFormattedCurrTimeStr = compose(formatTimeStr, Date.now);
