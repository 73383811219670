import { identity, curry, set } from "lodash";
import { isDev } from "./constants";

export const filterObj = (obj, fn) => {
  return Object.fromEntries(Object.entries(obj).filter(fn));
};
export const truthyProps = (obj) => filterObj(obj, ([k, v]) => !!v);

//redux
export const payloadSetter = (statePathToSet) => (state, action) => {
  return set(state, statePathToSet, action.payload);
};
export const toNextThousand = (num) => {
  return Math.ceil(num / 1000) * 1000;
};

export const devOnly = (toInvoke) => {
  if (!isDev) {
    return;
  }
  toInvoke();
};

export const getLSVal = (key) => {
  const result = localStorage.getItem(key);
  try {
    return JSON.parse(result);
  } catch {
    return undefined;
  }
};
export const setLSVal = (key, val) => {
  const result = JSON.stringify(val);
  localStorage.setItem(key, result);
};
