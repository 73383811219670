// https://developer.mozilla.org/en-US/docs/Web/API/Notifications_API/Using_the_Notifications_API

import { formatTimeStr } from "./util/formatters";

function askNotificationPermission() {
  function checkNotificationPromise() {
    try {
      Notification.requestPermission().then();
    } catch (e) {
      return false;
    }

    return true;
  }
  // function to actually ask the permissions
  function handlePermission(permission) {
    // set the button to shown or hidden, depending on what the user answers
    const btn = document.querySelector("#notif-enable");
    if (btn && (permission === "denied" || permission === "default")) {
      btn.style.display = "block";
    } else {
      btn.style.display = "none";
    }
  }

  // Let's check if the browser supports notifications
  if (!("Notification" in window)) {
    console.log("This browser does not support notifications.");
  } else {
    if (checkNotificationPromise()) {
      Notification.requestPermission().then((permission) => {
        handlePermission(permission);
      });
    } else {
      Notification.requestPermission(function (permission) {
        handlePermission(permission);
      });
    }
  }
}

const isGranted = () => {
  return Notification.permission === "granted";
};

export const sendIntervalEndNotif = (startISOStr, endISOStr) => {
  const [startTime, endTime] = [startISOStr, endISOStr].map(formatTimeStr);
  if (!isGranted()) {
    return;
  }
  navigator.serviceWorker.getRegistration().then((reg) => {
    reg.showNotification("Interval Complete");
  });
  // new Notification("Interval Complete", {
  //   body: `started: ${startTime}     ended: ${endTime}`,
  //   silent: !getLSVal("soundEnabled"),
  //   requireInteraction: false,
  // });
};

window.addEventListener(
  "notificationclick",
  (event) => {
    event.notification.close();
    window.open(window.location);
  },
  false
);

//TODO(lt): vvv include a disable button/option
export const NotifEnableButton = () => {
  const isPending = Notification.permission === "default";
  if (!isPending) {
    return null;
  }

  return (
    <button id="notif-enable" onClick={askNotificationPermission}>
      Enable Notifications
    </button>
  );
};
