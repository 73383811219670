import { isDev } from "./util/constants";
import { useCallback, useEffect } from "react";
import { useState } from "react";
import { devOnly, getLSVal, setLSVal, truthyProps } from "./util/utilities";
import { getFormattedCurrTimeStr } from "./util/formatters";
import { isProd } from "./util/constants";
import { fetchBlocks } from "./store/blocks";
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { memoize } from "lodash";

import store from "./store/store";
import {
  getCurrTimeTick,
  getIntervalEndDisplayStr,
  getIntervalEndISOStr,
  initTimer,
  startMvpInterval,
  stopMvpInterval,
} from "./store/timer";
import { getTimeUntilStr } from "./util/time";
import { setTitle } from "./util/dom";
import { NotifEnableButton } from "./NotifEnableButton";

const CurrTime = connect(
  (state) => ({
    currTimeTick: getCurrTimeTick(state),
  }),
  null
)(({ currTimeTick }) => {
  const [prevTimeStr, setPrevTimeStr] = useState(() => {
    return getFormattedCurrTimeStr();
  });

  useEffect(() => {
    devOnly(() => {
      const seconds = (str) => Number(str.slice(-5, -3));
      const shown = getFormattedCurrTimeStr();
      if (shown === prevTimeStr) {
        return;
      }
      if (seconds(shown) !== (seconds(prevTimeStr) + 1) % 60) {
        //TODO(leo): vvv find way to ensure no skips (when this occurs, just "lose" a second)?
        //TODO(leo): vvv see if your approach in taskChain had this issue
        // alert("skip");
      }

      setPrevTimeStr(shown);
    });
  }, [currTimeTick, prevTimeStr]);
  return (
    <div className="curr-time">Current Time: {getFormattedCurrTimeStr()}</div>
  );
});
const TimeRemaining = connect(
  (state) => ({
    intervalEndDisplayStr: getIntervalEndDisplayStr(state),
  }),
  null
)(({ intervalEndDisplayStr }) => {
  return (
    <div className="time-remaining">Remaining: {intervalEndDisplayStr}</div>
  );
});

class App extends React.Component {
  componentDidMount() {
    const { initTimer } = this.props;
    initTimer();
  }
  state = {
    intervalTimeMins: 15,
    soundEnabled: getLSVal("soundEnabled") || false,
  };
  render() {
    const {
      currTime,
      hasRunningInterval,
      intervalEndISOStr,
      startMvpInterval,
      stopMvpInterval,
    } = this.props;
    const { intervalTimeMins } = this.state;
    const toggleInterval = () => {
      if (hasRunningInterval) {
        stopMvpInterval();
      } else {
        startMvpInterval(intervalTimeMins);
      }
    };
    return (
      <>
        <NotifEnableButton />
        <CurrTime {...{ currTime }} />
        {hasRunningInterval && <TimeRemaining />}
        <button onClick={toggleInterval}>
          {hasRunningInterval ? "Stop" : "Start"}
        </button>
        <input
          {...{
            disabled: hasRunningInterval,
            type: "number",
            step: "1",
            min: "1",
            max: "999",
            value: this.state.intervalTimeMins,
            onChange: ({ target: { value } }) =>
              this.setState({
                intervalTimeMins: value ? Number(value) : value,
              }),
          }}
        />
        <div style={{ marginTop: "32px" }}>
          <input
            id="sound-checkbox"
            type="checkbox"
            onChange={(e) => {
              const { checked: newVal } = e.target;
              this.setState({ soundEnabled: newVal });
              setLSVal("soundEnabled", newVal);
            }}
            checked={this.state.soundEnabled}
          />
          <label htmlFor="sound-checkbox">Sounds Enabled</label>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  const intervalEndISOStr = getIntervalEndISOStr(state);
  return {
    intervalEndISOStr,
    hasRunningInterval: !!intervalEndISOStr,
  };
};

const mapDispatchToProps = {
  fetchBlocks,
  initTimer,
  startMvpInterval,
  stopMvpInterval,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(App);
