import { compose, createSlice } from "@reduxjs/toolkit";
import * as workerTimers from "worker-timers";
import { sendIntervalEndNotif } from "../NotifEnableButton";
import { setTitle } from "../util/dom";
import { getTimeUntilStr, minsToMs } from "../util/time";
import { getLSVal, payloadSetter } from "../util/utilities";

let intervalId = null,
  timeoutId = null,
  endAlertTimeoutId = null,
  mvpIntervalTimeoutId = null;

const clearTimers = () => {
  if (intervalId) {
    workerTimers.clearInterval(intervalId);
  }
  if (timeoutId) {
    workerTimers.clearTimeout(timeoutId);
  }
};

const clearMvpIntervalTimeout = () => {
  if (mvpIntervalTimeoutId) {
    workerTimers.clearTimeout(mvpIntervalTimeoutId);
    mvpIntervalTimeoutId = null;
  }
};

const playIntervalSound = () => {
  if (!getLSVal("soundEnabled")) {
    return;
  }
  const el = document.querySelector("#interval-sound");
  if (!el) {
    return;
  }
  el.currentTime = 0;
  if (el.paused) {
    el.play();
  }
};

export const initTimer = () => (dispatch, getState) => {
  clearTimers();

  dispatch(tick());
  intervalId = workerTimers.setInterval(() => {
    dispatch(tick());
  }, 1000);
};

const loopIntervalEndAlert = (intervalStartISO, intervalEndISO) => (
  dispatch,
  getState
) => {
  const getIsLeftRunning = () => !!getIntervalEndISOStr(getState());

  //loop it to be annoying and force user to click Stop
  const playAlert = () => {
    clearTimeout(endAlertTimeoutId);
    const isLeftRunning = getIsLeftRunning();
    if (isLeftRunning) {
      playIntervalSound();
      sendIntervalEndNotif(intervalStartISO, intervalEndISO);
      endAlertTimeoutId = setTimeout(playAlert, 2000);
    }
  };

  playAlert();
};

export const startMvpInterval = (timeMins) => (dispatch, getState) => {
  clearMvpIntervalTimeout();

  const scheduleIntervalEnd = () => {
    const endTimeMillis = Date.now() + minsToMs(timeMins);
    const intervalEndISO = new Date(endTimeMillis).toISOString();
    const intervalStartISO = new Date().toISOString();
    dispatch(setIntervalEndISOStr(intervalEndISO));
    dispatch(setIntervalStartISOStr(intervalStartISO));
    //TODO(leo): revisit below
    mvpIntervalTimeoutId = workerTimers.setTimeout(() => {
      dispatch(loopIntervalEndAlert(intervalStartISO, intervalEndISO));
      //start next one automatically- don't do yet
      // scheduleIntervalEnd();
    }, minsToMs(timeMins));
  };

  scheduleIntervalEnd();
};
export const stopMvpInterval = () => (dispatch, getState) => {
  clearMvpIntervalTimeout();

  dispatch(setIntervalEndISOStr(""));
  dispatch(setIntervalStartISOStr(""));
};

const tick = () => (dispatch, getState) => {
  const currTimeTick = getCurrTimeTick(getState());

  dispatch(
    setCurrTimeTick(
      currTimeTick < Number.MAX_SAFE_INTEGER ? currTimeTick + 1 : 1
    )
  );
  setTitle(getIntervalEndDisplayStr(getState()));
};

const slice = createSlice({
  name: "timer",
  initialState: {
    //used to cause rerenders on next tick
    currTimeTick: 0,
    intervalEndISOStr: "",
  },
  reducers: {
    setCurrTimeTick: payloadSetter("currTimeTick"),
    setTimeRemaining: payloadSetter("timeRemaining"),
    setIntervalEndISOStr: payloadSetter("intervalEndISOStr"),
    setIntervalStartISOStr: payloadSetter("intervalStartISOStr"),
  },
});

export const getCurrTimeTick = (state) => state.timer.currTimeTick;
export const getIntervalEndISOStr = (state) => state.timer.intervalEndISOStr;
export const getIntervalStartISOStr = (state) =>
  state.timer.intervalStartISOStr;
export const getIntervalEndDisplayStr = compose(
  getTimeUntilStr,
  getIntervalEndISOStr
);

const { reducer, actions } = slice;
const {
  setCurrTimeTick,
  setIntervalEndISOStr,
  setIntervalStartISOStr,
} = actions;
export { setCurrTimeTick };
export default reducer;
