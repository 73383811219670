import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { isEmpty, last } from "lodash";
import axios from "../util/axios";
import { payloadSetter } from "../util/utilities";

const blocksRoot = "/api/blocks";

//ultra MVP:
//just sends notif every hh interval, displays that in app

export const fetchBlocks = createAsyncThunk(
  "blocks/fetchBlocks",
  (_, { dispatch }) => {
    return axios.get(blocksRoot).then((response) => {
      const blocks = response.data;
      dispatch(setAll(blocks));

      dispatch(setAll(blocks));
      //assume last one is current
      if (!isEmpty(blocks)) {
        dispatch(setCurrBlockId(last(blocks).id));
      }
      return blocks;
    });
  }
);

/*
{
    id,
    preSummary: '',
    postSummary: '',
    startTime: ISO,
    endTime: ISO,
}

*/

export const updateBlock = createAsyncThunk(
  "blocks/updateBlocks",
  ({ newBlock }, { dispatch }) => {
    return axios
      .put(`${blocksRoot}/${newBlock.id}`, newBlock)
      .then((response) => {
        dispatch(updateOne(response.data));
        return response.data;
      });
  }
);

const adapter = createEntityAdapter({
  selectId: (block) => block.id,
});

const slice = createSlice({
  name: "blocks",
  initialState: { ...adapter.getInitialState(), currBlockId: null },
  reducers: {
    setAll: adapter.setAll,
    addOne: adapter.addOne,
    updateOne: adapter.updateOne,
    removeOne: adapter.removeOne,
    removeMany: adapter.removeMany,

    updateMany: adapter.updateMany,
    setCurrBlockId: payloadSetter("currBlockId"),
  },
});
const { reducer, actions } = slice;
const { setAll, updateOne, setCurrBlockId } = actions;

export const { selectAll: getAllBlocks } = adapter.getSelectors(
  (state) => state.blocks
);
export default reducer;
