import { configureStore } from "@reduxjs/toolkit";

import logger from "redux-logger";
import { isDev } from "../util/constants";
import { truthyProps } from "../util/utilities";

import tasks from "./tasks";
import timer, { setCurrTimeTick } from "./timer";
import blocks from "./blocks";

const reducer = {
  tasks,
  timer,
  blocks,
};

const preloadedState = null;

const store = configureStore({
  reducer,
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  devTools: isDev,
  ...truthyProps({ preloadedState }),
});

export default store;
