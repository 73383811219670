import * as dayjs from "dayjs";
import { formatTimeRemainingDisplay } from "./formatters";

export const getCurrTimeMSRounded = () => {
  return Math.round(Date.now() / 1000) * 1000;
};

export const getTimeDifferenceStr = (startISOStr, endISOStr) => {
  const diffMillis =
    new Date(endISOStr).getTime() - new Date(startISOStr).getTime();

  //currently no need ever to show negative values
  return formatTimeRemainingDisplay(Math.max(diffMillis, 0));
};
export const getTimeUntilStr = (endISOStr) => {
  console.log(!!endISOStr);
  return endISOStr
    ? getTimeDifferenceStr(new Date().toISOString(), endISOStr)
    : "";
};
export const getNearestBlockStart = () => {
  const now = new Date();
  const hrsPart = now.getUTCHours();
  const minsPart = now.getUTCMinutes();
};
export const minsToMs = (mins) => mins * 60 * 1000;
