import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "tasks",
  initialState: {},
});

const { reducer, actions } = slice;

export default reducer;
