import axios from "axios";

import { API_ORIGIN } from "./constants";

const instance = axios.create({
  baseURL: API_ORIGIN,
});

const loggerInstance = axios.create({
  baseURL: API_ORIGIN,
});

export { loggerInstance as loggerAxios };
export default instance;
